<template>
  <div>
    <v-dialog v-model="ModalEdit" persistent max-width="80%">
      <v-card>
        <div v-if="refpelaksana.length == 0">
          <v-skeleton-loader type="card, article"></v-skeleton-loader>
        </div>

        <div v-else>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Pilih Atasan
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                Lengkapi Data
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <div class="px-5 pt-5 mb-n3">
                <h3>EDIT DATA JABATAN</h3>
              </div>

              <v-stepper-content step="1">
                <v-card class="mb-5" flat>
                  <organization-chart
                    :datasource="dataset"
                    @node-click="(nodeData) => pilihatasan(nodeData)"
                  >
                    <template slot-scope="{ nodeData }">
                      <v-card class="px-2" outlined>
                        <small>
                          {{ nodeData.title }}
                        </small>
                      </v-card>
                    </template>
                  </organization-chart>
                </v-card>

                <v-btn
                  @click="closeModal()"
                  color="blue-grey lighten-4"
                  depressed
                >
                  Batal
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card class="mb-5 py-3" flat>
                  <v-text-field
                    v-model="unor_name"
                    label="Unit"
                    outlined
                    dense
                    readonly
                    filled
                  ></v-text-field>

                  <v-text-field
                    v-model="atasan.title"
                    label="Nama Atasan"
                    outlined
                    dense
                    readonly
                    filled
                  ></v-text-field>

                  <v-divider class="mb-4 mt-n3"></v-divider>

                  <v-row dense>
                    <v-col cols="12" :md="sjj == 'kosong' ? 10 : 4">
                      <v-dialog v-model="Djj" scrollable max-width="50%">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="sjj.nama"
                            dense
                            flat
                            outlined
                            readonly
                            v-on="on"
                            v-bind="attrs"
                            label="Jenis Jabatan"
                          ></v-text-field>
                        </template>

                        <v-form ref="pilihjj">
                          <v-card class="pa-3">
                            <div>
                              <strong> Pilih Jenis Jabatan </strong>
                            </div>

                            <v-divider class="my-3"></v-divider>

                            <div class="mb-n5">
                              <v-select
                                outlined
                                dense
                                return-object
                                :items="refjj"
                                @change="cjj"
                                item-key="id"
                                item-text="nama"
                              >
                              </v-select>
                            </div>

                            <v-card-text style="height: 300px">
                              <div>
                                <v-treeview
                                  activatable
                                  return-object
                                  :items="bawahan"
                                  :active.sync="seljj"
                                  item-key="id"
                                  item-text="nama"
                                  item-children="bawahan"
                                  selection-type="independent"
                                ></v-treeview>
                              </div>
                            </v-card-text>

                            <v-divider class="my-3"></v-divider>

                            <div class="text-right pb-3">
                              <v-btn
                                depressed
                                dark
                                color="grey darken-1"
                                @click="clearjj()"
                              >
                                Clear
                              </v-btn>

                              <v-btn
                                depressed
                                class="mx-2"
                                color="error"
                                @click="Djj = false"
                              >
                                Batal
                              </v-btn>

                              <v-btn
                                depressed
                                color="primary"
                                @click="Djj = false"
                              >
                                Pilih
                              </v-btn>
                            </div>
                          </v-card>
                        </v-form>
                      </v-dialog>
                    </v-col>

                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="sjj.kelompok"
                        label="Rumpun Jabatan"
                        outlined
                        dense
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>

                    <!-- ESELON -->
                    <v-col
                      cols="12"
                      md="6"
                      v-if="
                        sjj.atasan == 2 ||
                        sjj.id == 25 ||
                        sjj.id == 7 ||
                        sjj.id == 8
                      "
                    >
                      <v-autocomplete
                        v-model="SData.eselon.id"
                        :items="refeselon"
                        dense
                        outlined
                        item-value="id"
                        item-text="nama"
                        label="Eselon"
                      ></v-autocomplete>
                    </v-col>

                    <!-- FUNGSIONAL -->
                    <v-col
                      cols="12"
                      md="6"
                      v-if="
                        sjj.atasan == 13 ||
                        sjj.atasan == 14 ||
                        sjj.atasan == 19 ||
                        sjj.atasan == 28 ||
                        sjj.atasan == 29 ||
                        sjj.atasan == 34
                      "
                    >
                      <v-autocomplete
                        v-model="SData.fungsional.id"
                        :items="reffungsional"
                        dense
                        outlined
                        return-object
                        item-value="id"
                        item-text="nama"
                        label="Fungsional"
                        @change="cfungsional"
                      ></v-autocomplete>
                    </v-col>

                    <!-- PELAKSANA -->
                    <v-col
                      cols="12"
                      md="6"
                      v-if="sjj.atasan == 9 || sjj.id == 9"
                    >
                      <v-autocomplete
                        v-model="SData.pelaksana.id"
                        :items="refpelaksana"
                        dense
                        outlined
                        return-object
                        item-value="id"
                        item-text="nama"
                        label="Pelaksana"
                        @change="cpelaksana"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="SData.namaJabatan"
                        label="Nama Jabatan"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="SData.bup"
                        label="Batas Usia Pensiun"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="SData.kelas_jabatan"
                        :items="refkelasjabatan"
                        label="Kelas Jabatan"
                        item-value="id"
                        item-text="nama"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="SData.work_place"
                        :items="refworkplace"
                        label="Work Place"
                        item-value="id"
                        item-text="nama"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="SData.kebutuhan"
                        label="Kebutuhan"
                        outlined
                        dense
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row>
                  <v-col cols="10">
                    <v-btn color="primary" class="mr-2" @click="e1 = 1">
                      <v-icon left> mdi-arrow-left </v-icon>
                      Kembali
                    </v-btn>

                    <v-btn
                      @click="closeModal()"
                      color="blue-grey lighten-4"
                      depressed
                    >
                      Batal
                    </v-btn>
                  </v-col>

                  <v-col cols="2" class="text-right">
                    <v-btn color="success" @click="edit()" v-if="LBtn">
                      <v-icon left> mdi-content-save </v-icon>
                      Simpan
                    </v-btn>

                    <v-btn color="success" loading v-else>
                      <v-icon left> mdi-content-save </v-icon>
                      Simpan
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";
import OrganizationChart from "vue-organization-chart";

import SView from "@/store/anjab/petajabatan/view";
import SEdit from "@/store/anjab/petajabatan/edit";

export default {
  components: {
    OrganizationChart,
  },

  computed: {
    ModalEdit: {
      get() {
        return SEdit.state.ModalEdit;
      },
      set(value) {
        SEdit.commit("TModal", value);
      },
    },

    SData: {
      get() {
        return SEdit.state.data;
      },

      set(value) {
        console.log(value);
      },
    },

    sjj() {
      if (!this.seljj.length) {
        let data = {
          id: this.SData.janis_jabatan.id,
          nama: this.SData.janis_jabatan.nama,
          atasan: this.SData.janis_jabatan.atasan,
          kelompok: this.SData.janis_jabatan.kelompok,
        };
        return data;
      } else {
        let data = {
          id: this.seljj[0].id,
          nama: this.seljj[0].nama,
          atasan: this.seljj[0].atasan,
          kelompok: this.seljj[0].kelompok,
        };
        return data;
      }
    },
  },

  watch: {
    async ModalEdit(value) {
      if (value) {
        this.refjj = await getRef.new_jj(this.token);
        this.refeselon = await getRef.eselon(this.token);
        this.reffungsional = await getRef.fungsional(this.token);
        this.refpelaksana = await getRef.new_pelaksana(this.token);
        this.refworkplace = await getRef.workplace(this.token);
        this.refkelasjabatan = await getRef.kelasjabatan(this.token);
        this.getData();
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    unor_name: localStorage.getItem("unor"),
    LBtn: true,

    e1: 1,
    Djj: false,

    refeselon: [],
    refpelaksana: [],
    reffungsional: [],

    refjj: [],
    seljj: [],
    refworkplace: [],
    refkelasjabatan: [],

    bawahan: [],
    dataset: {},

    atasan: "",
  }),

  methods: {
    cjj(value) {
      this.bawahan = value.bawahan;
    },

    clearjj() {
      this.$refs.pilihjj.reset();
    },

    cfungsional(value) {
      this.SData.namaJabatan = value.nama;
      this.SData.bup = value.bup;
    },

    cpelaksana(value) {
      this.SData.namaJabatan = value.nama;
      this.SData.bup = value.bup;
    },

    reset() {
      this.refeselon = [];
      this.refpelaksana = [];
      this.reffungsional = [];

      this.dataset = {};

      this.atasan = "";
    },

    convert(value) {
      const files = JSON.stringify(value);
      var parsed = JSON.parse(files, function (k, v) {
        if (k === "namaJabatan") {
          this.title = v;
        } else if (k === "bawahan") {
          this.children = v;
        } else {
          return v;
        }
      });

      return parsed;
    },

    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_API + "anjab/all";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          this.dataset = this.convert(res.data.mapData[0]);
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log("Error", error.message);
        });
    },

    pilihatasan(value) {
      this.atasan = value;
      this.e1 = 2;
    },

    async edit() {
      this.LBtn = false;
      let data = {
        idJab: this.$route.params.id,
        parent: this.atasan.id,
        namaJabatan: this.SData.namaJabatan,
        unor: {
          id: this.SData.unor.id,
        },
        janis_jabatan: {
          id: this.sjj.id,
        },
        eselon: {
          id: this.SData.eselon.id,
        },
        pelaksana: {
          id: this.SData.pelaksana.id,
        },
        fungsional: {
          id: this.SData.fungsional.id,
        },
        bup: this.SData.bup,
        work_place: this.SData.work_place,
        kelas_jabatan: this.SData.kelas_jabatan,
        kebutuhan: this.SData.kebutuhan,
      };

      const url = process.env.VUE_APP_API + "anjab/edit";
      this.axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.LBtn = true;
          if (res.data.success) {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("berhasilAlert", true);
            SView.commit("gagalAlert", false);
            SView.commit("success", res.data.success);
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", res.data.success);
          }

          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.LBtn = true;
            this.closeModal();
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.LBtn = true;
            this.closeModal();
          }
        });
    },

    async closeModal() {
      await SView.commit("refreshData", true);
      this.reset();
      this.e1 = 1;
      this.ModalEdit = false;
    },
  },
};
</script>
